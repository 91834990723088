import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
const marked = require("marked");

const TemplateAboutUs = ({ title, subtitle, description, image, anchor = "", cta = "" }) => {

  return (
    <>
      <Helmet>
        <title>{title} {subtitle}</title>
        <meta name="Description" content={`${title} ${subtitle}`} />
      </Helmet>
      <section className="sectionHalfBackgroundRight py5 py140md">
        <div className="container">
          <div className="row">
            <div className="colMd6 mb5 mb0md sectionContent">
              <h2 className="sectionTitle">{title} <span>{subtitle}</span></h2>
              <div dangerouslySetInnerHTML={{__html: marked(description)}} />
              {anchor && <Link to={anchor} className="btn btnPrimary mt15">{cta}</Link>}
            </div>
            <div className="colMd5">
              <figure className="sectionImage">
                <img src={image} className="imgFluid" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TemplateAboutUs
