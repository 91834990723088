import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "slick-carousel/slick/slick.css"
import Slider from "react-slick"


const Testimonials = () => {

  const data = useStaticQuery(graphql`
    query  {
      strapiTestimonial {
        title {
          Title
          Subtitle
        }
        item {
          author
          feedback
          rating
          image {
            publicURL
          }
        }
      }
    }
  `)

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <i
        className={`fas fa-chevron-right textLg pointer ${className}`}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <i
        className={`fas fa-chevron-left textLg pointer ${className}`}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const { title, item } = data.strapiTestimonial

  return (
    <section className="py5 py140md backgroundSecondaryDark textDefault">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="sectionTitle textDefault textCenter">
              {title.Title} <span>{title.Subtitle}</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="colMd8 offsetMd2 textCenter">
            <i className="fas fa-quote-left text2lg textPrimaryLight mb3" />
            <Slider {...settings} className="testimonialsSlider">
              {
                item && item.length && item.map((testimonial, index) => {
                  const { author, feedback, image, rating } = testimonial
                  return <div key={index}>
                    <div className="mb15">
                      <p>{feedback}</p>
                    </div>
                    <div className="testimonialsDetails">
                      <div className="mb4">
                        {
                          rating && Array.from(Array(rating), (e, index) => {
                            return <i key={index} className="fas fa-star textPrimary mr05" />
                          })
                        }
                      </div>
                      <div className="dInlineBlock mb3 testimonialsAvatar">
                        <img src={image.publicURL} alt="" className="imgFluid" />
                      </div>
                      <h3 className="testimonialsAuthor mb3 textPrimary text1sm">{author}</h3>
                    </div>
                  </div>
                })
              }
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
